

.StripeElement{
    width: 100%;
}


.StripeElement *{
    color: blue; 
}
